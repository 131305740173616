import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'
import router from './router'
import store from './store'
// style
import '@assets/styles/index.scss'
// interceptor - 路由拦截器
import '@/router/interceptor'
// 自定义指令
import directive from '@/directive'
import components from '@/components'

(window as any)._AMapSecurityConfig = {
  securityJsCode: 'a9695c94aba90319e2b2ddc5a23f4360'
}

const app = createApp(App)
components.install(app)
directive.install(app)
app.use(store).use(createPinia()).use(router).mount('#app')

// // webstorm特殊注释符号
// // TODO 记录，可生成导航列表
// // region 折叠开始
// // endregion 折叠结束
// // region TODO 组合使用
// /* eslint-disable */ 在代码顶部添加一行注释，禁用本文件的eslint验证
// /* eslint-disable no-new */ 还可以在注释后加入详细规则，这样就能避开指定的校验规则了
// // eslint-disable-line 禁用当前行
// // eslint-disable-next-line 禁用下一行
// // @ts-ignore 忽视本行代码的小错误
// // @ts-nocheck 忽略全文
// // @ts-check 取消忽略全文
