exports.env = function () {
  return {
    // 开发环境变量
    // 修改BASE_API需要重跑
    development: {
      // BASE_API: 'http://192.168.1.141:8080/aote',
      BASE_API: 'https://lub.yueshichuandong.cn/aote',
      // BASE_API: 'http://192.168.1.168:8081/aote',
      // BASE_API2: 'https://lub.yueshichuandong.cn/weixin',
      BASE_API2: 'http://192.168.1.141:8081',
      WEBSOCKET: 'wss://lub.yueshichuandong.cn/aote/websocket/',
      IMAGE_PREFIX: 'https://lub.yueshichuandong.cn/',

      // BASE_API: 'http://192.168.3.146:8086/aote',
      // BASE_API2: 'http://192.168.99.181:8081',
      // BASE_API2: 'http://192.168.3.146:6912',

      // BASE_API: 'http://192.168.99.181:9090/aote',
      // BASE_API2: 'http://192.168.99.165:8081',
      // BASE_API: 'http://192.168.99.181:8080/aote',
      // BASE_API: 'https://iottest.yuankonginfo.com',
      // BASE_API: 'http://8.140.155.218:10066',
      // BASE_API2: 'http://192.168.99.164:6912',
      // BASE_API: 'http://182.92.208.167:52000',
      // BASE_API: 'http://8.140.155.218:10066',
      // BASE_API: 'http://192.168.99.181:10066',
      // WEBSOCKET: 'ws://192.168.99.181:8080/websocket/',
      // WEBSOCKET: 'ws://8.140.155.218:10066/websocket/',
    },
    // 生产环境变量
    production: {
      // BASE_API: 'http://123.52.43.67:5074/aote',
      // BASE_API2: 'https://123.52.43.67:5074/weixin',
      // WEBSOCKET: 'ws://123.52.43.67:5074/aote/websocket/',
      // IMAGE_PREFIX: 'http://123.52.43.67:5075/'

      // BASE_API: 'http://127.0.0.1:8080/aote',
      // BASE_API2: 'https://127.0.0.1:8081/weixin',
      // WEBSOCKET: 'ws://127.0.0.1:8080/aote/websocket/',
      // IMAGE_PREFIX: 'http://127.0.0.1:8080/'

      BASE_API: 'https://lub.yueshichuandong.cn/aote',
      BASE_API2: 'https://lub.yueshichuandong.cn/weixin',
      WEBSOCKET: 'wss://lub.yueshichuandong.cn/aote/websocket/',
      IMAGE_PREFIX: 'https://lub.yueshichuandong.cn/'
    }
  }
}
